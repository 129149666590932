@import url('https://fonts.googleapis.com/css2?family=Lato&family=Playfair+Display&family=Poppins&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');
// colors
$cream: #eae5d6;
$yellow: #fab232;
$light-orange: #F99931;
$orange: #f58432;
$black: #343434;
$grey: #585757;

$color-primary: #F2654F;
$color-secondary: #C1BAB7;
$color-accent: #3E363E;
$color-3397e67: #D7DADB;
$color-7593cc6: #000000;
$color-981747b: #7B7979;
$color-939484f: #E7E1D7;
$color-060319b: #FADBBB;
$color-13d21e2: #CFC9BA;
$color-876b74f: #40363F;
$color-df0aa39: #E0D9CE;
$primary-font-family: "Tenor Sans", sans-serif;
$typography-primary-font-size: 3em;
$typography-primary-font-weight: 400;
$typography-primary-line-height: 1.4em;
$typography-primary-letter-spacing: -0.5px;
$typography-secondary-font-family: "Helvetica";
$typography-secondary-font-size: 1.6em;
$typography-secondary-font-weight: 400;
$typography-secondary-line-height: 1.4em;
$typography-text-font-family: "Helvetica";
$typography-text-font-weight: 400;
$typography-text-line-height: 1.4em;
$typography-accent-font-size: 1.4em;
$typography-accent-font-weight: 300;
$typography-accent-line-height: 1.4em;
$typography-bfc03db-font-size: 2.6em;
$typography-bfc03db-line-height: 1.4em;
$typography-bfc03db-letter-spacing: -1px;
$typography-10cb08d-font-size: 0.9em;
$typography-10cb08d-line-height: 1.4em;
$typography-d1f7d20-font-size: 20px;


// gradient
$gradient: linear-gradient(90deg, rgba(250, 178, 50, 1) 0%, rgba(249, 153, 49, 1) 50%, rgba(245, 132, 50, 1) 100%);
$buy-gradient: linear-gradient(90deg, rgb(241, 186, 91) 0%, rgb(244, 169, 89) 50%, rgb(250, 140, 62) 100%);

$transparent-gradient: linear-gradient(90deg, rgba(250, 178, 50, 1) 0%, rgba(249, 153, 49, .7) 50%, rgba(245, 132, 50, 1) 100%);
$header-gradient: radial-gradient(circle, rgba(250, 178, 50, 1) 0%, rgba(249, 153, 49, 1) 50%, rgba(245, 132, 50, 1) 100%);
$title-gradient: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(234, 229, 214, 1) 69%, rgba(250, 178, 50, 1) 100%);
$faded-gradient: linear-gradient(90deg, rgba(250, 178, 50, .7) 0%, rgba(249, 153, 49, .7) 50%, rgba(245, 132, 50, 1) 100%);
$f-gradient: linear-gradient(90deg, rgba(250, 178, 50, .5) 0%, rgba(249, 153, 49, .5) 50%, rgba(245, 132, 50, .5) 100%);

// shadows
$box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
$text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

// padding-margin
$padding: 0 5% 5% 5%;

// transition
$transition: .5s all ease;

// font-family
$header-font: 'Roboto', sans-serif;
$font-2: 'Lato', sans-serif;
$font-3: 'Playfair Display', serif;
$font-4: 'Poppins', sans-serif;