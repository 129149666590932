@import './variables.scss';
@import './mixins.scss';
@import './animations.scss';

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background: $color-060319b;
}

::-webkit-scrollbar-thumb {
    background: $color-primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.title {
    text-align: center;

    margin-bottom: 5%;
    position: relative;
    color: $color-accent;

    h4 {
        font-size: 2em;
        text-transform: capitalize;
        font-family: $primary-font-family;
    }

    p {
        // text-transform: lowercase;
        font-size: 1.1em;
        font-family: $primary-font-family;
    }

    &::before {
        content: "";
        position: absolute;
        top: 130%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10%;
        height: 2px;
        border-radius: 100%;
        background: $color-accent;
    }
}

@font-face {
    font-family: Font1;
    src: url('../Fonts/Helvetica-Font/Helvetica.ttf');
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: inherit;
    // font-family: Font2;
    // font-weight: bold;
}

p,
li,
a {
    font-family: Font3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $primary-font-family;
}


header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3000;
    padding: 1% 2%;
    background: transparent;
    // background: $color-939484f;

    .logo {
        width: 20%;

        h1 {
            font-family: $primary-font-family;
            color: #000;
            margin-left: 10%;
            font-size: 50px;
        }
    }

    .links {
        margin-left: auto;
        width: 50%;

        a {
            // color: #fff;
            padding: 2% 4%;
            font-family: $primary-font-family;
            color: #000 !important;
            font-size: 20px;
            font-weight: bold;
            cursor: default;
        }
    }

    .menu {
        width: 40px;
        height: 40px;
        fill: $color-accent;
        display: none;
        margin-right: 10%;
    }

    #navbar-mobile {
        display: none;
        position: fixed;
        right: 0;
        top: 0;
        width: 60%;
        height: 100%;
        padding: 5%;
        background: $color-accent;
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);
        animation: slide-in-right .5s 1;

        .links {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-top: 30%;

            a {
                margin-bottom: 10%;
                font-size: 20px;
                color: #fff !important;
                padding-left: 0;
                font-weight: normal;
            }
        }

        .cross {
            width: 30px;
            height: 30px;
            padding: 2%;
            fill: #fff;
            margin-left: auto;
            position: absolute;
            top: 2%;
            right: 10%;
        }
    }

}

// .slider {
//     height: 100vh;

//     .slider-body {
//         // background: $color-939484f !important;
//         height: 100%;
//         padding: 0 5%;
//         background-image: url("../Image/slider2.png");
//         background-size: cover;
//         // background-position: -10% 0;
//         background-repeat: no-repeat;

//         // position: relative;
//         .car1 {
//             .slider-card {
//                 .text {

//                     p {
//                         animation: none !important;
//                     }

//                     a {
//                         animation: none !important;
//                     }
//                 }

//                 img {
//                     animation: none !important;
//                 }

//             }
//         }

//         // .__active {

//         //     .slider-card {
//         //         .text {

//         //             p {
//         //                 animation: slide-in-up 1s 1;
//         //             }

//         //             a {
//         //                 animation: blur-in 1s 1;
//         //             }
//         //         }

//         //         img {
//         //             animation: slide-in-right 1s 1;
//         //         }

//         //     }
//         // }

//         .alice-carousel__next-btn {

//             img {
//                 width: 40px;
//                 fill: $color-accent;
//                 height: 40px;
//                 position: absolute;
//                 top: 50%;
//                 right: -2%;
//             }
//         }

//         .alice-carousel__prev-btn {

//             img {
//                 width: 40px;
//                 height: 40px;
//                 transform: scaleX(-1);
//                 fill: $color-accent;
//                 margin-right: 10%;
//                 position: absolute;
//                 top: 50%;
//                 left: -4%;
//                 // box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.3);
//                 border-radius: 10px;
//                 // background: #fff;

//             }
//         }

//         .slider-card {
//             // display: flex;
//             // justify-content: space-between;
//             // align-items: center;
//             padding: 7% 0 0 0;

//             img {
//                 width: 28%;
//                 display: none;
//                 margin: auto;
//                 filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
//             }

//             .text {
//                 padding: 5% 0;
//                 width: 50%;
//                 margin-top: 5%;

//                 p {
//                     font-size: 35px;
//                     text-transform: capitalize;
//                     font-family: $primary-font-family;
//                     color: $color-accent;
//                     margin-bottom: 10%;
//                     margin-left: 5%;
//                     line-height: 180%;
//                     // text-align: center;
//                     font-weight: bold;
//                 }

//                 ul {
//                     display: flex;
//                     flex-wrap: wrap;
//                     // justify-content: center;
//                     margin-bottom: 10%;

//                     li {
//                         font-size: 18px;
//                         text-transform: capitalize;
//                         font-family: Font1;
//                         color: #fff;
//                         margin-bottom: 1%;
//                         line-height: 180%;
//                         width: calc(100% / 3.2);
//                         padding: 1%;
//                         background: $color-accent;
//                         border-radius: 10px;
//                         margin-right: 1%;
//                         text-align: center;
//                     }
//                 }

//                 a {

//                     font-family: Font1;
//                     font-size: 20px;
//                     font-weight: 700;
//                     line-height: 1.2em;
//                     color: #FFFFFF;
//                     background-color: $color-primary;
//                     box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5);
//                     border-radius: 8px;
//                     padding: 2% 5%;
//                     letter-spacing: 1px;
//                     cursor: default;
//                     margin-left: 5%;
//                 }
//             }
//         }
//     }
// }

.banner {
    position: relative;

    img {
        width: 100%;
    }

    .banner-card {
        width: 45%;
        position: absolute;
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);

        p {
            font-size: 35px;
            text-transform: capitalize;
            font-family: $primary-font-family;
            color: $color-accent;
            margin-bottom: 10%;
            margin-left: 5%;
            line-height: 180%;
            // text-align: center;
            font-weight: bold;
        }


        a {

            font-family: Font1;
            font-size: 20px;
            font-weight: 700;
            line-height: 1.2em;
            color: #FFFFFF;
            background-color: $color-primary;
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5);
            border-radius: 8px;
            padding: 2% 5%;
            letter-spacing: 1px;
            cursor: default;
            margin-left: 5%;
        }
    }
}

section {
    padding: 5%;
    background: $color-939484f;


    &:nth-child(even) {
        background: #F7F5EE;
    }
}

.collared {
    // padding: 5%;

    .about {
        // padding: 5%;
        // background: $color-939484f;


        .about-body {
            display: flex;
            align-items: stretch;
            justify-content: space-between;

            img {
                width: 30%;
                // background: #f9f6f6;
                // padding: 5%;
                object-fit: contain;
            }

            .about-text {
                width: 70%;
                padding-left: 3%;

                p {
                    line-height: 150%;
                    font-size: 18px;
                    font-family: Font1;
                    color: $color-876b74f;

                }

                a {

                    font-family: Font1;
                    font-size: 18px;
                    font-weight: 700;
                    color: #FFFFFF;
                    background-color: $color-accent;
                    // box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.8);
                    border-radius: 8px;
                    padding: 2% 8%;
                    letter-spacing: 1px;
                    cursor: default;
                }

                .about-points {
                    margin-top: 2%;
                    color: $color-876b74f;

                    li {
                        display: flex;

                        .svg {
                            width: 30px;
                            height: 30px;
                            margin-right: 5%;

                            svg {
                                width: 30px;
                                height: 30px;
                                fill: $color-accent;
                            }

                        }

                        .pt {
                            h6 {
                                font-size: 20px;
                                font-weight: bold;
                            }

                            p {
                                font-size: 18px;
                            }
                        }

                        list-style-type: none;
                    }
                }
            }


        }
    }

    .assure {
        // padding: 5%;
        // background: #F7F5EE;

        .assure-body {
            width: 70%;
            margin: auto;

            li {
                display: flex;
                align-items: center;
                margin-bottom: 2%;

                img {
                    width: 10%;
                    // height: 50px;
                }

                p {
                    margin: 0;
                    font-family: Font1;

                    margin-left: 5%;
                    font-size: 18px;
                }
            }

            p {
                font-weight: bold;
                margin-left: 5%;
                font-size: 20px;
                font-family: Font1;

                margin-top: 5%;

                span {
                    color: $color-primary;
                }
            }
        }
    }

    .orthopedics {
        // padding: 5%;
        position: relative;
        // background: $color-939484f;

        .title {
            position: relative;
            z-index: 2;
        }


        .orthopedics-body {

            display: flex;
            align-items: stretch;
            justify-content: center;
            flex-wrap: wrap;
            position: relative;
            z-index: 2;

            .alice-carousel__prev-btn,
            .alice-carousel__next-btn {
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 20px;
                height: 20px;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);
                border-radius: 50%;

                svg {
                    width: 20px;
                    height: 20px;
                    fill: $color-primary;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .alice-carousel__prev-btn {
                transform: scaleX(-1);
                left: -2%;
                margin-top: -1%;
            }

            .alice-carousel__next-btn {
                right: -1%;
            }


            .orthopedics-card {
                width: 95%;
                background: $color-secondary;
                // color: #fff;
                border-radius: 10px;
                margin-right: 1%;
                margin-bottom: 1%;
                padding: 5%;
                height: 30vh;

                h3 {
                    font-size: 20px;
                    font-family: $primary-font-family;
                    text-transform: capitalize;
                    text-align: center;
                    font-weight: bold;
                    margin-bottom: 5%;

                }

                p {
                    font-size: 18px;
                    font-family: Font1;
                    line-height: 140%;
                }
            }
        }
    }

    .indications {
        // padding: 5%;
        // background: #F7F5EE;

        .indications-body {
            display: flex;
            justify-content: center;
            align-items: stretch;

            // .indications-card {
            //     display: flex;
            //     margin-right: 2%;

            //     .indications-card-left {
            //         width: 40%;
            //         // margin: 2%;
            //         box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);

            //         img {
            //             width: 100%;
            //             height: 100%;
            //             object-fit: cover;
            //         }
            //     }

            //     .indications-card-right {
            //         width: 60%;
            //         margin-left: 2%;
            //         display: flex;
            //         flex-direction: column;

            //         ul {
            //             padding: 0;
            //         }

            //         li {
            //             display: flex;
            //             font-family: $primary-font-family;
            //             margin-bottom: 2%;
            //             padding: 3%;
            //             background: #fff;
            //             box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);

            //             &:last-child {
            //                 margin-bottom: 0;
            //             }

            //             .svg {
            //                 width: 20px;
            //                 height: 20px;
            //                 margin-right: 5%;

            //                 svg {
            //                     width: 20px;
            //                     height: 20px;
            //                     fill: $color-accent;
            //                 }

            //             }


            //             b {
            //                 font-size: 18px;
            //                 font-weight: bold;
            //             }

            //             p {
            //                 font-size: 18px;
            //                 display: flex;
            //                 flex-direction: column;
            //             }


            //             list-style-type: none;
            //         }
            //     }
            // }

            .indications-card {
                width: calc(100% / 4);
                position: relative;

                margin: 1%;
                border-radius: 10px;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.8);

                img {
                    // position: absolute;
                    // top: 0;
                    // left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 1;
                    border-radius: 10px;
                }

                .indications-text {
                    position: absolute;
                    bottom: 0;
                    z-index: 2;
                    padding: 5%;
                    width: 100%;
                    height: 25%;
                    color: #fff;
                    background: rgba($color: $color-accent, $alpha: 0.7);
                    border-radius: 0 0 10px 10px;

                    h3 {
                        font-size: 25px;
                        font-weight: bold;
                        text-transform: capitalize;
                        font-family: $primary-font-family;
                    }

                    p {
                        font-size: 18px;
                        font-family: Font1;


                    }
                }
            }
        }
    }

    .dosage {
        .dosage-body {
            p {
                font-family: Font1;
                font-size: 18px;
                text-align: center;
            }
        }
    }

    .gynaecology {
        // padding: 5%;
        background: $color-939484f;
        background: url("../Image/gynaecology.png");
        background-repeat: no-repeat;
        background-size: cover;
        // background-attachment: fixed;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($color: $color-939484f, $alpha: 0.4);
            z-index: 1;
        }

        .title {
            position: relative;
            z-index: 2;
        }

        .gynaecology-body {
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 2;

            .alice-carousel__prev-btn,
            .alice-carousel__next-btn {
                position: absolute;
                top: 55%;
                transform: translate(-50%, -50%);
                width: 20px;
                height: 20px;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);
                border-radius: 50%;

                svg {
                    width: 20px;
                    height: 20px;
                    fill: $color-accent;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .alice-carousel__prev-btn {
                transform: scaleX(-1);
                left: -2%;
                margin-top: -1%;
            }

            .alice-carousel__next-btn {
                right: -2%;
            }

            .gynaecology-card {
                width: 95%;
                border-radius: 10px;
                margin: 2%;
                background: $color-939484f;
                padding: 5%;
                position: relative;
                height: 35vh;
                margin-top: 10%;
                border: 1px solid rgba($color: #000, $alpha: 0.5);
                display: flex;
                flex-direction: column;

                // color: #fff;
                img {
                    position: absolute;
                    right: -3%;
                    width: 7%;
                    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
                    z-index: 200;
                }

                h3 {
                    font-size: 20px;
                    font-family: $primary-font-family;
                    // text-transform: capitalize;
                    text-align: center;
                    font-weight: bold;
                    margin: 5% 0;
                    height: 5vh;

                }

                p {
                    font-size: 18px;
                    font-family: Font1;
                    line-height: 140%;
                    flex-grow: 1;
                }

                span {
                    position: absolute;
                    top: -10%;
                    left: 5%;
                    background: $color-primary;
                    font-weight: bold;
                    border-radius: 50%;
                    padding: 1%;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);
                    border: 1px solid #fff;
                    color: #fff;
                }
            }
        }
    }

    .free {
        // padding: 5%;
        // background: #F7F5EE;

        .free-body {
            display: flex;
            justify-content: center;
            align-items: stretch;

            .free-card {
                width: calc(100% / 5);
                margin-right: 2%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 1%;
                margin-top: 3%;

                img {
                    width: 80px;
                    height: 80px;
                    margin-bottom: 5%;
                }

                p {
                    text-align: center;
                    font-size: 18px;
                    text-transform: capitalize;
                    flex-grow: 1;
                    color: $color-accent;
                    // font-weight: bold;
                    font-family: Font1;

                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .clinical {
        // background: $color-939484f;
        // padding: 5%;

        .clinical-body {
            padding: 0 5%;
            display: flex;

            .clinical-card {
                width: calc(100% / 3);
                border-radius: 10px;
                background: #F7F5EE;
                padding: 2%;
                margin-right: 2%;
                // box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);
                position: relative;
                margin-top: 2%;
                display: flex;
                flex-direction: column;


                // &:nth-child(2),
                // &:nth-child(3) {
                //     img {
                //         width: 60%;
                //         display: block;
                //         margin: 5% auto 0 auto;
                //     }

                //     ul {
                //         margin-top: 40%;

                //     }

                //     .span {
                //         display: flex;
                //         justify-content: space-between;
                //         margin-top: -30%;

                //         span {
                //             width: calc(100% / 2);
                //             font-size: 18px;
                //             color: #000;
                //             font-family: Font1;
                //             text-align: left;

                //             &:last-child {
                //                 text-align: right;
                //             }
                //         }
                //     }
                // }

                h6 {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: $color-primary;
                    color: #fff;
                    font-family: $primary-font-family;
                    padding: 2% 6%;
                    text-align: center;
                    border-radius: 20px;
                    width: 40%;

                }

                h5 {
                    font-family: $primary-font-family;
                    color: $color-accent;
                    font-size: 20px;
                    text-align: center;
                    text-transform: capitalize;
                    // margin-bottom: 2%;
                    line-height: 150%;
                    margin-top: 3%;
                }

                .img {
                    display: flex;

                    img {
                        width: calc(100% / 2);
                        border-radius: 5px;
                        margin: 5% auto;
                        display: block;
                        // box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);
                        border: 1px solid $color-939484f;

                        &:last-child {
                            margin-left: 2%;
                        }
                    }
                }


                p {
                    font-family: Font1;
                    font-size: 18px;
                    color: $color-accent;
                    text-align: center;
                    margin-top: 10%;
                }

                ul {
                    margin-top: 5%;
                    flex-grow: 1;
                    padding: 0;

                    li {
                        font-family: Font1;
                        font-size: 18px;
                        color: $color-accent;
                        display: flex;
                        margin-bottom: 2%;

                        .svg {
                            width: 20px;
                            height: 20px;
                            margin-right: 5%;

                            svg {
                                width: 20px;
                                height: 20px;
                                fill: $color-accent;
                            }

                        }
                    }
                }

                .span {
                    display: flex;
                    justify-content: space-between;

                    span {
                        width: calc(100% / 2);
                        font-size: 18px;
                        color: #000;
                        font-family: Font1;
                        text-align: center;
                    }
                }
            }
        }
    }

    .why {
        // padding: 5%;
        // background: #F7F5EE;
        background: url("../Image/why.png");
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;

        .readbtn {
            text-align: center;
            text-decoration: underline;
            font-family: Font1;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            cursor: pointer;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 95%;
            left: 50%;
        }

        .read {
            background: #F7F5EE;
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 5%;
            box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.5);
            z-index: 3000;
            animation: slide-in-up .5s 1;

            svg {
                width: 30px;
                height: 30px;
                fill: $color-accent;
                position: absolute;
                right: 5%;
                top: 5%;
            }

            &::before {
                content: "";
                height: 100%;
                width: 100%;
                z-index: -1;
                position: absolute;
                top: -100%;
                left: 0;
                background: rgba($color: #000, $alpha: 0.6);
                animation: blur-in 3s 1;

            }

            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    font-size: 20px;
                    font-family: Font1;
                    width: calc(100% / 2.2);
                    list-style-type: disc;
                    margin-right: 1%;
                    line-height: 180%;
                    color: $color-accent;
                    margin-bottom: 2%;
                }
            }
        }

        .why-body {
            display: flex;
            justify-content: space-between;

            .why-body-left {
                margin-left: 10%;
            }

            .why-body-right {
                margin-right: 10%;
            }

            .why-card {
                width: 220px;
                height: 220px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                // box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
                padding: 10%;
                text-align: center;
                line-height: 150%;
                margin-bottom: 10%;
                background: rgba($color: $color-939484f, $alpha: 0.6);
                border: 1px solid #fff;

                &:hover {
                    background: $color-939484f;
                }

                h3 {
                    font-family: Font1;
                    text-transform: capitalize;
                    font-size: 20px;
                    font-weight: bold;
                    margin: 0;
                }

                p {
                    font-size: 18px;
                    margin: 0;
                    margin-top: 3%;
                }
            }
        }

    }

    .imageBanner {
        padding: 3% 5%;
        background: url("../Image/imagebanner.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        height: 85vh;

        h4 {
            font-size: 2.2em;
            text-transform: capitalize;
            font-family: $primary-font-family;
            text-align: center;
        }

        p {
            // text-transform: lowercase;
            font-size: 20px;
            font-family: Font1;
            text-align: center;
            // width: 80%;
            // margin: auto;
            // margin-top: 25%;


        }
    }

    .table {
        // padding: 5%;
        // background: #F7F5EE;
        margin: 0;

        .table-body {
            width: 90%;
            margin: auto;
            background: transparent;

            .row1 {
                display: flex;
                align-items: stretch;
                justify-content: flex-start;
                border-bottom: 1px solid #000;
                background: transparent;

                .col {
                    padding: 1%;
                    width: 40%;
                    font-size: 25px;
                    font-weight: bold;
                    color: $color-accent;
                    font-family: Font1;
                    background: transparent;
                }

                .col1 {
                    padding: 14px;
                    width: 20%;
                    font-weight: bold;
                    font-size: 18px;
                    color: $color-accent;
                    text-transform: capitalize;
                    font-family: Font1;
                    background: transparent;
                }

                .col2 {
                    padding: 14px;
                    width: 40%;
                    background: $color-primary;
                    background: rgba($color: $color-primary, $alpha: 0.3);
                    margin-left: 2%;
                    font-size: 18px;
                    color: $color-accent;
                    font-family: Font1;
                }

                .col3 {
                    padding: 2%;
                    width: 40%;
                    background: $color-3397e67;
                    margin-left: 1%;
                    font-size: 18px;
                    color: $color-accent;
                    font-family: Font1;

                }
            }
        }
    }


    .diet {
        // padding: 5%;
        // background: #F7F5EE;

        .diet-body {
            display: flex;
            justify-content: center;
            align-items: stretch;

            .diet-card {
                width: calc(100% / 3);
                margin-right: 2%;

                img {
                    width: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                }

                .text {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 5%;

                    h3 {
                        font-family: $primary-font-family;
                        color: $color-accent;
                        text-transform: capitalize;
                        font-size: 25px;
                        margin: 0;

                        // text-align: center;
                    }

                    button {
                        width: 30%;
                        background: $color-primary;
                        border: none;
                        color: #fff;
                        text-transform: capitalize;
                        padding: 2%;
                        font-size: 18px;
                        font-family: Font1;
                        letter-spacing: 1px;
                        // box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5);
                        border-radius: 5px;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }

                .diet-recipe {
                    background: #F7F5EE;
                    position: fixed;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    padding: 2% 10%;
                    box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.5);
                    z-index: 3000;
                    animation: slide-in-up .5s 1;
                    height: 80vh;
                    overflow-y: scroll;

                    svg {
                        width: 30px;
                        height: 30px;
                        fill: $color-accent;
                        position: absolute;
                        right: 5%;
                        top: 5%;
                    }

                    h4 {
                        font-size: 20px;
                        font-family: $primary-font-family;
                        font-weight: bold;
                    }

                    h3 {
                        font-size: 30px;
                        font-family: $primary-font-family;
                        font-weight: bold;
                        text-align: center;
                        margin-bottom: 5%;
                        color: $color-primary;
                    }

                    ul {
                        // display: flex;
                        // flex-wrap: wrap;

                        li {
                            font-size: 18px;
                            font-family: Font1;
                            // width: calc(100% / 2.2);
                            list-style-type: circle;
                            margin-right: 3%;
                            line-height: 150%;
                            color: $color-accent;
                            // margin-bottom: 2%;
                        }
                    }

                    ol {


                        li {
                            list-style-type: circle;
                            font-size: 18px;
                            font-family: Font1;
                            color: $color-accent;
                        }
                    }

                    p {
                        font-size: 18px;
                        font-family: Font1;
                        color: $color-accent;
                    }
                }
            }
        }
    }

    .contact {
        .contact-body {
            background: $color-accent;
            margin: 0 5%;
            border-radius: 20px;

            form {
                padding: 5%;

                .form {
                    display: flex;
                    // flex-direction: column;
                    flex-wrap: wrap;


                    input,
                    select {
                        width: calc(100%/3.2);
                        margin-bottom: 2%;
                        background: transparent;
                        border-radius: 10px;
                        padding: 2% 4%;
                        border: 2px solid $color-981747b;
                        font-family: Font1;
                        font-size: 18px;
                        margin-right: 2%;
                        color: $color-981747b;
                        outline: none;

                        &::placeholder {
                            color: $color-981747b;
                        }
                    }
                }



                button {
                    padding: 2%;
                    background: $color-primary;
                    color: #fff;
                    border-radius: 10px;
                    border: none;
                    font-family: Font1;
                    font-size: 18px;
                    width: 100%;
                }
            }
        }
    }

}


footer {
    background: $color-939484f;
    padding: 5% 10% 0 10%;

    .footer-top {

        display: flex;
        justify-content: space-between;

        .footer-subscribe {
            width: 50%;

            h3 {
                font-family: $primary-font-family;
                text-transform: capitalize;
            }

            p {
                font-size: 18px;
                font-family: Font1;
            }

            .subscribe {
                margin-top: 5%;
                display: flex;


                input {
                    background: transparent;
                    padding: 1% 2%;
                    border: 1px solid #000;
                    border-radius: 4px;
                    width: 80%;
                    margin-right: 3%;
                }

                button {
                    background: $color-accent;
                    color: #fff;
                    padding: 2%;
                    width: 20%;
                    border: none;
                    border-radius: 4px;
                    font-family: $primary-font-family;
                }
            }

            .footer-follow {
                margin-top: 15%;

                a {
                    margin-right: 3%;

                    svg {
                        width: 30px;
                        height: 30px;
                        fill: $color-accent;

                        path {
                            fill: $color-accent;
                        }
                    }
                }

            }
        }

        // .footer-about {
        //     width: 30%;
        //     padding: 0 5%;
        //     margin-left: 10%;

        //     h4 {
        //         color: $color-accent;
        //         font-weight: bold;
        //         font-family: Font1;
        //         font-size: 20px;
        //         margin-bottom: 5%;
        //         line-height: 200%;
        //     }

        //     ul {
        //         padding: 0;

        //         li {
        //             margin-bottom: 2%;

        //             a {
        //                 color: $color-accent;
        //                 font-family: Font1;
        //                 font-size: 18px;
        //                 font-weight: 100;
        //                 cursor: default;
        //             }

        //             // line-height: 200%;
        //         }
        //     }
        // }

        .footer-contact {
            width: 30%;

            h4 {
                color: $color-accent;
                font-weight: bold;
                font-family: Font1;
                font-size: 20px;
                margin-bottom: 5%;
            }

            ul {
                padding: 0;

                li {
                    color: $color-accent;
                    font-family: Font1;
                    font-size: 18px;
                    font-weight: 100;
                    line-height: 200%;
                    display: flex;
                    align-items: baseline;

                    .svg {
                        width: 20px;
                        height: 20px;
                        margin-right: 5%;

                    }

                    svg {
                        width: 20px;
                        height: 20px;
                        fill: $color-accent;
                    }

                    a {
                        text-decoration: none;
                        font-family: Font1;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .footer-copyright {
        border-top: 1px solid rgba($color: #000000, $alpha: 0.6);
        margin-top: 5%;
        padding: 2%;
        display: flex;

        p {
            font-family: Font1;
        }

        .copy {
            margin-left: auto;
            width: 30%;

            a {
                border-right: 1px solid #000;
                font-family: Font1;
                background: $color-accent;
                color: #fff;
                padding: 2% 4%;
                border-radius: 4px;
                text-align: center;

                &:last-child {
                    border-right: 0;
                    // padding-left: 2%;
                }
            }
        }
    }

}


/*--------------------------------------------------------------
# Media Queries
--------------------------------------------------------------*/
$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) {

    p,
    a,
    li {
        font-size: 16px;
    }

    .title {
        h4 {
            font-size: 22px;
        }

        p {
            font-size: 16px;
        }
    }

    header {
        background: $color-939484f !important;
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5) !important;
        padding: 3%;

        .logo {
            h1 {
                font-size: 35px;
                margin-bottom: 0;
            }
        }

        .links {
            display: none;
        }

        .menu {
            display: block;
        }
    }

    .banner {
        // height: 60vh;
        margin-top: 15%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .banner-card {
            width: 50%;
            top: 50%;

            p {
                font-size: 13px;
            }

            a {
                font-size: 16px;
            }
        }
    }

    .collared {
        .about {
            .about-body {
                flex-direction: column;

                img,
                .about-text {
                    width: 100%;
                }

                .about-text {
                    margin-top: 5%;

                    p {
                        font-size: 16px;
                    }

                    a {
                        width: 100%;
                        display: block;
                        text-align: center;
                        font-size: 16px;
                    }

                    .about-points {
                        li {
                            .pt {
                                h6 {
                                    font-size: 18px;
                                }

                                p {
                                    font-size: 14px;
                                }
                            }

                            .svg {
                                width: 20px;
                                height: 20px;

                                svg {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .assure {
            .assure-body {
                width: 100%;
                margin-top: 5%;

                ul {
                    padding: 0;
                }

                li {
                    margin-bottom: 5%;

                    img {
                        width: 15%;
                    }

                    p {
                        text-align: left;
                        font-size: 16px;
                        font-weight: normal;

                    }
                }

                p {
                    text-align: center;
                    font-size: 16px;
                    margin-left: 0;
                }
            }
        }

        .orthopedics {
            .orthopedics-body {
                .alice-carousel__prev-btn {
                    margin-top: -3%;
                }

                .orthopedics-card {
                    height: 25vh;

                    h3 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }

        .indications {

            .indications-body {
                // flex-direction: column;
                flex-wrap: wrap;

                .indications-card {
                    // height: 70vh;
                    width: calc(100% / 2.1);
                    margin-bottom: 5%;

                    .indications-text {
                        height: 45%;

                        h3 {
                            font-size: 18px;
                        }

                        p {
                            font-size: 13px;
                        }
                    }
                }
            }
        }

        .dosage {
            .dosage-body {
                p {
                    font-size: 16px;
                    margin-top: 7%;
                }
            }
        }

        .gynaecology {
            .gynaecology-body {
                .gynaecology-card {
                    height: 25vh;

                    h3 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }

        .free {
            .free-body {
                // flex-wrap: wrap;

                .free-card {
                    width: calc(100% / 3);

                    // &:last-child{
                    //     width: 100%;
                    // }

                    img {
                        width: 50px;
                        height: 50px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }

        .clinical {
            .clinical-body {
                flex-wrap: wrap;
                padding: 0;

                .clinical-card {
                    width: 100%;
                    margin-top: 15%;
                    padding: 5%;

                    h6 {
                        width: 60%;
                    }

                    .span {
                        span {
                            font-size: 16px;
                        }
                    }

                    ul {
                        li {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .why {
            background-position: 50%;

            .readbtn {
                font-size: 16px;
            }

            .read {
                ul {
                    flex-direction: column;

                    li {
                        font-size: 18px;
                        width: 90%;
                    }
                }
            }

            .why-body {

                .why-body-left {
                    margin-left: 0%;
                }

                .why-body-right {
                    margin-right: 0%;
                }

                .why-card {
                    width: 150px;
                    height: 150px;

                    h3 {
                        font-size: 14px;
                    }

                    p {
                        font-size: 12px;
                        line-height: 120%;
                    }
                }
            }
        }

        .imageBanner {
            height: 50vh;
            background-position: 50%;

            h4 {
                font-size: 22px;
            }

            p {
                font-size: 16px;
                background: rgba($color: #fff, $alpha: 0.6);
                margin-top: 60%;
            }
        }

        .table {
            .table-body {
                width: 100%;

                .row1 {
                    flex-direction: column;

                    // &:first-child {
                    //     flex-direction: row;
                    // }

                    .col {
                        font-size: 18px;
                        width: 100%;
                        padding: 2%;

                        &:nth-child(2) {
                            background: rgba($color: $color-primary, $alpha: 0.3);
                        }

                        &:nth-child(3) {
                            background: #D7DADB;
                        }
                    }

                    .col1,
                    .col2,
                    .col3 {
                        width: 100%;
                    }

                    .col2 {
                        font-size: 16px;
                        margin: 0;
                    }

                    .col3 {
                        margin: 0;
                        font-size: 16px;
                    }
                }
            }
        }

        .diet {
            .diet-body {
                flex-direction: column;

                .diet-card {
                    width: 100%;
                    margin-top: 5%;

                    .text {
                        h3 {
                            font-size: 20px;
                        }

                        button {
                            font-size: 16px;
                        }
                    }

                    .diet-recipe {
                        padding: 2% 4%;

                        h3 {
                            font-size: 25px;
                            margin-top: 3%;
                        }

                        p {
                            font-size: 16px;
                        }

                        ul,
                        ol {
                            li {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        .contact {
            .contact-body {
                form {
                    .form {
                        flex-direction: column;

                        input,
                        select {
                            width: 100%;
                            margin-right: 0;
                            margin-bottom: 5%;
                            font-size: 16px;
                        }
                    }

                    button {
                        font-size: 16px;
                    }
                }
            }
        }

    }

    footer {
        .footer-top {
            flex-direction: column;

            .footer-subscribe,
            .footer-about,
            .footer-contact {
                width: 100%;
            }

            .footer-subscribe {
                .footer-follow {
                    margin-top: 5%;
                    margin-bottom: 5%;
                }

                .subscribe {
                    input {
                        width: 70%;
                    }

                    button {
                        width: 30%;
                    }
                }

                p {
                    font-size: 16px;
                }
            }

            .footer-about {
                margin-left: 0;
                padding-left: 0;
                margin-top: 5%;
            }

            .footer-contact {
                margin-top: 5%;

                ul {
                    li {
                        font-size: 16px;

                        a {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .footer-copyright {
            flex-direction: column;

            p {
                margin-bottom: 2%;
            }

            .copy {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {

    p,
    a,
    li {
        font-size: 16px;
    }

    .title {
        h4 {
            font-size: 25px;
        }

        p {
            font-size: 16px;
        }
    }

    header {
        background: $color-939484f !important;
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5) !important;
        padding: 3%;

        .logo {
            h1 {
                font-size: 35px;
                margin-bottom: 0;
            }
        }

        .links {
            display: none;
        }

        .menu {
            display: block;
        }
    }

    .banner {
        // height: 60vh;
        margin-top: 12%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .banner-card {
            width: 50%;
            top: 55%;

            p {
                font-size: 20px;
            }

            a {
                font-size: 18px;
            }
        }
    }

    .collared {
        .about {
            .about-body {
                flex-direction: column;

                img,
                .about-text {
                    width: 100%;
                }

                img {
                    width: 70%;
                    margin: auto;
                    display: block;
                }

                .about-text {
                    margin-top: 5%;

                    p {
                        font-size: 16px;
                    }

                    a {
                        width: 100%;
                        display: block;
                        text-align: center;
                        font-size: 16px;
                    }

                    .about-points {
                        li {
                            .pt {
                                h6 {
                                    font-size: 18px;
                                }

                                p {
                                    font-size: 14px;
                                }
                            }

                            .svg {
                                width: 20px;
                                height: 20px;

                                svg {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .assure {
            .assure-body {
                width: 100%;
                margin-top: 5%;

                ul {
                    padding: 0;
                }

                li {
                    margin-bottom: 5%;

                    img {
                        width: 15%;
                    }

                    p {
                        text-align: left;
                        font-size: 16px;
                        font-weight: normal;

                    }
                }

                p {
                    text-align: center;
                    font-size: 16px;
                    margin-left: 0;
                }
            }
        }

        .orthopedics {
            .orthopedics-body {
                margin-top: 10%;

                .alice-carousel__prev-btn {
                    margin-top: -3%;
                }

                .orthopedics-card {
                    height: 33vh;
                    width: 97%;

                    h3 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 16px;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .indications {

            .indications-body {
                // flex-direction: column;
                flex-wrap: wrap;

                .indications-card {
                    // height: 70vh;
                    width: calc(100% / 2.1);
                    margin-bottom: 5%;

                    .indications-text {
                        height: 25%;

                        h3 {
                            font-size: 22px;
                        }

                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .dosage {
            .dosage-body {
                p {
                    font-size: 16px;
                    margin-top: 7%;
                }
            }
        }

        .gynaecology {
            .gynaecology-body {
                .gynaecology-card {
                    h3 {
                        font-size: 20px;
                        height: 8vh;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }

        .free {
            .free-body {
                // flex-wrap: wrap;

                .free-card {
                    width: calc(100% / 3);

                    // &:last-child{
                    //     width: 100%;
                    // }

                    img {
                        width: 50px;
                        height: 50px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }

        .clinical {
            .clinical-body {
                flex-wrap: wrap;
                padding: 0;

                .clinical-card {
                    width: calc(100% / 2.1);
                    margin-bottom: 5%;
                    padding: 5%;

                    h6 {
                        width: 50%;
                    }

                    .span {
                        span {
                            font-size: 16px;
                        }
                    }

                    ul {
                        li {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .why {
            background-position: 50%;

            .readbtn {
                font-size: 18px;
            }

            .read {
                ul {
                    flex-direction: column;

                    li {
                        font-size: 18px;
                        width: 90%;
                    }
                }
            }

            .why-body {

                .why-body-left {
                    margin-left: 0%;
                }

                .why-body-right {
                    margin-right: 0%;
                }

                .why-card {
                    width: 170px;
                    height: 170px;

                    h3 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 120%;
                    }
                }
            }
        }

        .imageBanner {

            h4 {
                font-size: 22px;
            }

            p {
                font-size: 16px;
            }
        }

        .table {
            .table-body {
                width: 100%;

                .row1 {
                    // flex-direction: column;

                    // &:first-child {
                    //     flex-direction: row;
                    // }

                    .col {
                        font-size: 18px;
                        width: 100%;
                        padding: 2%;
                    }

                    .col1,
                    .col2,
                    .col3 {
                        width: 100%;
                    }

                    .col2 {
                        font-size: 16px;
                        margin: 0;
                    }

                    .col3 {
                        margin: 0;
                        font-size: 16px;
                    }
                }
            }
        }

        .diet {
            .diet-body {
                // flex-direction: column;

                .diet-card {
                    width: calc(100% / 3.1);
                    margin-top: 5%;

                    .text {
                        h3 {
                            font-size: 18px;
                        }

                        button {
                            font-size: 14px;
                        }
                    }

                    .diet-recipe {
                        padding: 2% 4%;

                        h3 {
                            font-size: 25px;
                            margin-top: 3%;
                        }

                        p {
                            font-size: 16px;
                        }

                        ul,
                        ol {
                            li {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        .contact {
            .contact-body {
                form {
                    .form {
                        // flex-direction: column;

                        input,
                        select {
                            // width: 100%;
                            // margin-right: 0;
                            // margin-bottom: 5%;
                            font-size: 16px;
                        }
                    }

                    button {
                        font-size: 16px;
                    }
                }
            }
        }

    }

    footer {
        .footer-top {
            flex-direction: column;

            .footer-subscribe,
            .footer-about,
            .footer-contact {
                width: 100%;
            }

            .footer-subscribe {
                .footer-follow {
                    margin-top: 5%;
                    margin-bottom: 5%;
                }

                .subscribe {
                    input {
                        width: 70%;
                    }

                    button {
                        width: 30%;
                    }
                }

                p {
                    font-size: 18px;
                }
            }

            .footer-about {
                margin-left: 0;
                padding-left: 0;
                margin-top: 5%;
            }

            .footer-contact {
                // margin-top: 5%;

                ul {
                    li {
                        font-size: 18px;

                        a {
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        .footer-copyright {
            flex-direction: column;

            p {
                margin-bottom: 2%;
            }

            .copy {
                margin-left: 0;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {

    p,
    a,
    li {
        font-size: 16px;
    }

    .title {
        h4 {
            font-size: 25px;
        }

        p {
            font-size: 16px;
        }
    }

    header {
        background: $color-939484f !important;
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5) !important;
        padding: 3%;

        .logo {
            h1 {
                font-size: 35px;
                margin-bottom: 0;
            }
        }

        .links {
            display: none;
        }

        .menu {
            display: block;
        }
    }

    .banner {
        // height: 60vh;
        margin-top: 12%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .banner-card {
            width: 50%;
            top: 55%;

            p {
                font-size: 16px;
            }

            a {
                font-size: 16px;
            }
        }
    }

    .collared {
        .about {
            .about-body {
                flex-direction: column;

                img,
                .about-text {
                    width: 100%;
                }

                img {
                    width: 70%;
                    margin: auto;
                    display: block;
                }

                .about-text {
                    margin-top: 5%;

                    p {
                        font-size: 16px;
                    }

                    a {
                        width: 100%;
                        display: block;
                        text-align: center;
                        font-size: 16px;
                    }

                    .about-points {
                        li {
                            .pt {
                                h6 {
                                    font-size: 18px;
                                }

                                p {
                                    font-size: 14px;
                                }
                            }

                            .svg {
                                width: 20px;
                                height: 20px;

                                svg {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .assure {
            .assure-body {
                width: 100%;
                margin-top: 5%;

                ul {
                    padding: 0;
                }

                li {
                    margin-bottom: 5%;

                    img {
                        width: 15%;
                    }

                    p {
                        text-align: left;
                        font-size: 16px;
                        font-weight: normal;

                    }
                }

                p {
                    text-align: center;
                    font-size: 16px;
                    margin-left: 0;
                }
            }
        }

        .orthopedics {
            .orthopedics-body {
                .alice-carousel__prev-btn {
                    margin-top: -3%;
                }

                .orthopedics-card {
                    height: 25vh;
                    width: 97%;

                    h3 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }

        .indications {

            .indications-body {
                // flex-direction: column;
                flex-wrap: wrap;

                .indications-card {
                    // height: 70vh;
                    width: calc(100% / 2.1);
                    margin-bottom: 5%;

                    .indications-text {
                        height: 40%;

                        h3 {
                            font-size: 18px;
                        }

                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .dosage {
            .dosage-body {
                p {
                    font-size: 16px;
                    margin-top: 7%;
                }
            }
        }

        .gynaecology {
            .gynaecology-body {
                .gynaecology-card {
                    h3 {
                        font-size: 20px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }

        .free {
            .free-body {
                // flex-wrap: wrap;

                .free-card {
                    width: calc(100% / 3);

                    // &:last-child{
                    //     width: 100%;
                    // }

                    img {
                        width: 50px;
                        height: 50px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }

        .clinical {
            .clinical-body {
                flex-wrap: wrap;
                padding: 0;

                .clinical-card {
                    width: 100%;
                    margin-bottom: 5%;
                    padding: 5%;

                    .span {
                        span {
                            font-size: 16px;
                        }
                    }

                    ul {
                        li {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .why {
            background-position: 50%;

            .readbtn {
                font-size: 16px;
            }

            .read {
                ul {
                    flex-direction: column;

                    li {
                        font-size: 18px;
                        width: 90%;
                    }
                }
            }

            .why-body {

                .why-body-left {
                    margin-left: 0%;
                }

                .why-body-right {
                    margin-right: 0%;
                }

                .why-card {
                    width: 150px;
                    height: 150px;

                    h3 {
                        font-size: 14px;
                    }

                    p {
                        font-size: 12px;
                        line-height: 120%;
                    }
                }
            }
        }

        .imageBanner {

            h4 {
                font-size: 22px;
            }

            p {
                font-size: 16px;
            }
        }

        .table {
            .table-body {
                width: 100%;

                .row1 {
                    flex-direction: column;

                    // &:first-child {
                    //     flex-direction: row;
                    // }

                    .col {
                        font-size: 18px;
                        width: 100%;
                        padding: 2%;

                        &:nth-child(2) {
                            background: rgba($color: $color-primary, $alpha: 0.3);
                        }

                        &:nth-child(3) {
                            background: #D7DADB;
                        }
                    }

                    .col1,
                    .col2,
                    .col3 {
                        width: 100%;
                    }

                    .col2 {
                        font-size: 16px;
                        margin: 0;
                    }

                    .col3 {
                        margin: 0;
                        font-size: 16px;
                    }
                }
            }
        }

        .diet {
            .diet-body {
                flex-direction: column;

                .diet-card {
                    width: 100%;
                    margin-top: 5%;

                    .text {
                        h3 {
                            font-size: 20px;
                        }

                        button {
                            font-size: 16px;
                        }
                    }

                    .diet-recipe {
                        padding: 2% 4%;

                        h3 {
                            font-size: 25px;
                            margin-top: 3%;
                        }

                        p {
                            font-size: 16px;
                        }

                        ul,
                        ol {
                            li {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        .contact {
            .contact-body {
                form {
                    .form {
                        flex-direction: column;

                        input,
                        select {
                            width: 100%;
                            margin-right: 0;
                            margin-bottom: 5%;
                            font-size: 16px;
                        }
                    }

                    button {
                        font-size: 16px;
                    }
                }
            }
        }

    }

    footer {
        .footer-top {
            flex-direction: column;

            .footer-subscribe,
            .footer-about,
            .footer-contact {
                width: 100%;
            }

            .footer-subscribe {
                .footer-follow {
                    margin-top: 5%;
                    margin-bottom: 5%;
                }

                .subscribe {
                    input {
                        width: 70%;
                    }

                    button {
                        width: 30%;
                    }
                }

                p {
                    font-size: 16px;
                }
            }

            .footer-about {
                margin-left: 0;
                padding-left: 0;
                margin-top: 5%;
            }

            .footer-contact {
                margin-top: 5%;

                ul {
                    li {
                        font-size: 16px;

                        a {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .footer-copyright {
            flex-direction: column;

            p {
                margin-bottom: 2%;
            }

            .copy {
                margin-left: 0;
            }
        }
    }
}


@media (min-width: 768px) and (max-width: $lg) {

    p,
    a,
    li {
        font-size: 16px;
    }

    header {
        background: $color-939484f !important;
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5) !important;
        padding: 3%;

        .logo {
            h1 {
                font-size: 35px;
                margin-bottom: 0;
            }
        }

        .links {
            display: none;
        }


        .menu {
            display: block;
        }
    }

    .banner {
        margin-top: 10%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .banner-card {
            width: 50%;
            top: 55%;

            p {
                font-size: 32px;
            }
        }
    }

    .collared {
        .about {
            .about-body {
                flex-direction: column;

                img,
                .about-text {
                    width: 100%;
                }

                img {
                    width: 40%;
                    margin: auto;
                    display: block;
                }

                .about-text {
                    margin-top: 5%;


                    a {
                        width: 100%;
                        display: block;
                        text-align: center;
                    }

                }
            }
        }

        .why {
            background-position: 50%;


            .why-body {

                .why-body-left {
                    margin-left: 0%;
                }

                .why-body-right {
                    margin-right: 0%;
                }

            }
        }

        .clinical {
            .clinical-body {
                padding: 0;

                .clinical-card {
                    h6 {
                        width: 60%;
                    }

                    h5 {
                        font-size: 16px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    .span {
                        span {
                            font-size: 16px;
                        }
                    }

                    ul {
                        li {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .free {
            .free-body {
                .free-card {
                    width: calc(100% / 3);
                }
            }
        }

        .diet {
            .diet-body {
                // flex-direction: column;

                .diet-card {
                    width: calc(100% / 3.1);
                    margin-top: 5%;

                    .text {
                        h3 {
                            font-size: 18px;
                        }

                        button {
                            font-size: 14px;
                        }
                    }

                    .diet-recipe {
                        padding: 2% 4%;

                        h3 {
                            font-size: 25px;
                            margin-top: 3%;
                        }

                        p {
                            font-size: 16px;
                        }

                        ul,
                        ol {
                            li {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

    }

    footer {
        .footer-top {

            .footer-subscribe {

                .subscribe {
                    input {
                        width: 70%;
                    }

                    button {
                        width: 30%;
                    }
                }

            }

        }

    }
}

@media (min-width: 1025px) and (max-width: $xl) {
    header {
        .links {
            width: 60%;
        }
    }
}